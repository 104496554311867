<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5" style="min-height: 60vh">
        <div class="container">
          <div class="row">
            <div class="col-md-9" v-if="MagazineTopHeaderAd1 != null">
              <div class="w-100">
                <a :href="MagazineTopHeaderAd1.adUrl" target="_blank">
                  <img
                    v-lazy="MagazineTopHeaderAd1.image"
                    :alt="MagazineTopHeaderAd1.id"
                    style="width: 100%; max-height: 35vh; object-fit: fill"
                  />
                </a>
              </div>
            </div>

            <div class="offset-md-9 col-6 col-md-3">
              <selectize v-on:change="search()" :settings="settings">
                <!-- settings is optional -->
                <option value="" selected>Search Articles...</option>
              </selectize>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-md-2 mb-2 col-6 text-left">
              <div class="td-trending-now-title w-100 text-center">
                Trending Now
              </div>
            </div>
            <div class="col-md-10 magazine_trending text-left pl-md-0">
              <div v-for="(t, i) in Trending" :key="i" class="pr-2">
                <router-Link :to="'/magazine-details/' + t.id">
                  <span
                    v-html="
                      t.post_content.length > 80
                        ? t.post_content.substring(0, 80) + '...'
                        : t.post_content
                    "
                  ></span>
                  <!--{{(t.post_content).length>120?(t.post_content).substring(0,120)+"...":t.post_content}}-->
                </router-Link>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div
              class="col-md-6 pr-md-1 mb-sm-1 all left_big"
              v-for="(t, i) in (Header || []).slice(0, 1)"
              :key="i"
            >
              <div class="tainer">
                <img
                  v-lazy="t.image ? t.image : '../../assets/images/error.png'"
                  class="img-fluid left_big_image"
                  :alt="t.post_title"
                />
              </div>
              <div class="meta-info">
                <router-link
                  :to="'/magazine/category/' + c"
                  class="post-category mr-1"
                  v-for="(c, j) in t.categories"
                  :key="j"
                >
                  {{ c }}
                </router-link>
                <div class="left_big_info">
                  <h3 class="post-title">
                    <router-link
                      :to="'/magazine-details/' + t.id"
                      :title="t.post_title"
                    >
                      {{ t.post_title }}
                    </router-link>
                  </h3>
                </div>
                <div class="td-editor-date">
                  <span class="post-author"
                    ><a>{{ t.post_author }}</a>
                    <span v-if="t.post_date && t.post_author">-</span>
                  </span>
                  <span class="post-date"
                    ><time class="font-weight-bold">{{
                      getYear(t.post_date)
                    }}</time></span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-md-1">
              <div class="row side-right">
                <div
                  class="col-md-12 all mb-2"
                  v-for="(t, i) in (Header || []).slice(1, 2)"
                  :key="i"
                >
                  <div class="tainer">
                    <img
                      v-lazy="
                        t.image ? t.image : '../../assets/images/error.png'
                      "
                      class="img-fluid right_top_image"
                      :alt="t.post_title"
                    />
                  </div>
                  <div class="meta-info">
                    <router-link
                      :to="'/magazine/category/' + c"
                      class="post-category mr-1"
                      v-for="c in t.categories"
                      :key="c.id"
                      >{{ c }}
                    </router-link>
                    <div class="">
                      <h3 class="post-title">
                        <router-link
                          :to="'/magazine-details/' + t.id"
                          :title="t.post_title"
                        >
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                    </div>
                    <div class="td-editor-date">
                      <span class="post-author"
                        ><a>{{ t.post_author }}</a>
                        <span v-if="t.post_date && t.post_author">-</span>
                      </span>
                      <span class="post-date"
                        ><time class="font-weight-bold">{{
                          getYear(t.post_date)
                        }}</time></span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 all right_bottom"
                  v-for="(t, i) in Header.slice(2, 4)"
                  :key="i + 'a'"
                >
                  <div class="tainer">
                    <img
                      v-lazy="
                        t.image ? t.image : '../../assets/images/error.png'
                      "
                      class="img-fluid right_bottom_image"
                      :alt="t.post_title"
                    />
                  </div>
                  <div class="meta-info">
                    <router-link
                      :to="'/magazine/category/' + c"
                      class="post-category mr-1"
                      v-for="(c, j) in t.categories"
                      :key="j"
                      >{{ c }}
                    </router-link>
                    <div class="">
                      <h3 class="post-title-sm">
                        <router-link
                          :to="'/magazine-details/' + t.id"
                          :title="t.post_title"
                        >
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                    </div>
                    <div class="td-editor-date">
                      <span class="post-author"
                        ><a>{{ t.post_author }}</a>
                        <span v-if="t.post_date && t.post_author">-</span>
                      </span>
                      <span class="post-date"
                        ><time class="font-weight-bold">{{
                          getYear(t.post_date)
                        }}</time></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end of top header section-->

          <!--business section-->
          <div class="row mt-5">
            <div class="col-md-8">
              <div class="td-block-title-wrap">
                <router-link :to="'/magazine/category/Business'">
                  <h4 class="block-title td-block-title">
                    <span class="td-pulldown-size">Business</span>
                  </h4>
                </router-link>
              </div>
              <div class="row mb-5">
                <div
                  class="col-md-6"
                  v-for="(t, i) in (Business || []).slice(0, 1)"
                  :key="i + 'a'"
                >
                  <div style="position: relative">
                    <img
                      v-lazy="
                        t.image ? t.image : '../../assets/images/error.png'
                      "
                      :alt="t.post_title"
                      class="img-fluid w-100"
                      style="max-height: 304px; object-fit: cover"
                    />
                    <router-link
                      :to="'/magazine/category/Business'"
                      class="td-post-category"
                      >Business
                    </router-link>
                  </div>
                  <div class="td-module-meta-info">
                    <h3 class="entry-title">
                      <router-link :to="'/magazine-details/' + t.id">
                        {{ t.post_title }}
                      </router-link>
                    </h3>
                    <div class="td-editor-date">
                      <span class="author-date">
                        <span class="post-author"
                          ><a>{{ t.post_author }}</a> <span>-</span>
                        </span>
                        <span class="post-date"
                          ><time class="entry-date">{{
                            getYear(t.post_date)
                          }}</time></span
                        >
                        <!-- <span class="td-module-comments"
                          ><span href="">{{ t.comment_count }}</span></span
                        > -->
                      </span>
                    </div>
                    <div class="td-excerpt">
                      <div
                        v-html="
                          t.post_content.length > 120
                            ? t.post_content.substring(0, 120) + '...'
                            : t.post_content
                        "
                      ></div>
                    </div>
                  </div>
                  <!--add section here-->
                  <div class="w-100" v-if="MagazineBusinessAd1 != null">
                    <a :href="MagazineBusinessAd1.adUrl" target="_blank">
                      <img
                        v-lazy="MagazineBusinessAd1.image"
                        :alt="MagazineBusinessAd1.id"
                        style="max-height: 150px; object-fit: cover"
                      />
                    </a>
                  </div>
                  <!--end dd section here-->
                </div>
                <div class="col-md-6">
                  <ul class="list-unstyled">
                    <li
                      class="media mb-4"
                      v-for="(t, i) in Business.slice(1, 5)"
                      :key="i + 'b'"
                    >
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="mr-3 media_image"
                      />
                      <div class="media-body">
                        <h3 class="media-body-text">
                          <router-link :to="'/magazine-details/' + t.id"
                            >{{ t.post_title }}
                          </router-link>
                        </h3>
                        <time class="post-date">{{
                          getYear(t.post_date)
                        }}</time>
                      </div>
                    </li>
                    <!--add section here-->
                    <div class="w-100" v-if="MagazineBusinessAd2 != null">
                      <a :href="MagazineBusinessAd2.adUrl" target="_blank">
                        <img
                          v-lazy="MagazineBusinessAd2.image"
                          :alt="MagazineBusinessAd2.id"
                          style="max-height: 150px; object-fit: cover"
                        />
                      </a>
                    </div>
                    <!--end dd section here-->
                  </ul>
                </div>
              </div>

              <!--end of business section-->

              <!--IndustryPulse-->
              <div class="td-block-title-wrap">
                <router-link :to="'/magazine/category/IndustryPulse'">
                  <h4 class="block-title td-block-title block-title-grey">
                    <span class="td-pulldown-size">Industry Pulse</span>
                  </h4>
                </router-link>
              </div>
              <div class="row mb-5">
                <div
                  class="col-md-6"
                  v-for="(t, i) in (IndustryPulse || []).slice(0, 1)"
                  :key="i + 'c'"
                >
                  <div class="mb-4">
                    <div style="position: relative">
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="img-fluid w-100"
                        style="max-height: 304px; object-fit: cover"
                      />
                      <router-link
                        :to="'/magazine/category/IndustryPulse'"
                        class="td-post-category"
                        >IndustryPulse
                      </router-link>
                    </div>
                    <div class="td-module-meta-info">
                      <h3 class="entry-title">
                        <router-link :to="'/magazine-details/' + t.id">
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                      <div class="td-editor-date">
                        <span class="post-author"
                          ><a>{{ t.post_author }}</a>
                          <span v-if="t.post_date && t.post_author">-</span>
                        </span>
                        <span class="post-date"
                          ><time class="font-weight-bold">{{
                            getYear(t.post_date)
                          }}</time></span
                        >
                        <!-- <span class="td-module-comments"
                          ><span href="">{{ t.comment_count }}</span></span
                        > -->
                      </div>

                      <div class="td-excerpt">
                        <div
                          v-html="
                            t.post_content.length > 120
                              ? t.post_content.substring(0, 120) + '...'
                              : t.post_content
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                  <ul class="list-unstyled">
                    <li
                      class="media mb-4"
                      v-for="(t, i) in IndustryPulse.slice(3, 5)"
                      :key="i"
                    >
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="mr-3 media_image"
                      />
                      <div class="media-body">
                        <h3 class="media-body-text">
                          <router-link :to="'/magazine-details/' + t.id"
                            >{{ t.post_title }}
                          </router-link>
                        </h3>
                        <time class="post-date">{{
                          getYear(t.post_date)
                        }}</time>
                      </div>
                    </li>
                    <!--add section here-->
                    <div class="w-100" v-if="MagazineIndustryPulseAd1 != null">
                      <a :href="MagazineIndustryPulseAd1.adUrl" target="_blank">
                        <img
                          v-lazy="MagazineIndustryPulseAd1.image"
                          :alt="MagazineIndustryPulseAd1.id"
                          style="max-height: 150px; object-fit: cover"
                        />
                      </a>
                    </div>
                    <!--end dd section here-->
                  </ul>
                </div>
                <div
                  class="col-md-6"
                  v-for="(t, i) in (IndustryPulse || []).slice(1, 2)"
                  :key="i + 'd'"
                >
                  <div class="mb-4">
                    <div style="position: relative">
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="img-fluid w-100"
                        style="max-height: 304px; object-fit: cover"
                      />
                      <router-link
                        :to="'/magazine/category/IndustryPulse'"
                        class="td-post-category"
                        >IndustryPulse
                      </router-link>
                    </div>
                    <div class="td-module-meta-info">
                      <h3 class="entry-title">
                        <router-link :to="'/magazine-details/' + t.id">
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                      <div class="td-editor-date">
                        <span class="post-author"
                          ><a>{{ t.post_author }}</a>
                          <span v-if="t.post_date && t.post_author">-</span>
                        </span>
                        <span class="post-date"
                          ><time class="font-weight-bold">{{
                            getYear(t.post_date)
                          }}</time></span
                        >
                        <!-- <span class="td-module-comments"
                          ><span href="">{{ t.comment_count }}</span></span
                        > -->
                      </div>

                      <div class="td-excerpt">
                        <div
                          v-html="
                            t.post_content.length > 120
                              ? t.post_content.substring(0, 120) + '...'
                              : t.post_content
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                  <ul class="list-unstyled">
                    <li
                      class="media mb-4"
                      v-for="(t, i) in IndustryPulse.slice(5, 7)"
                      :key="i"
                    >
                      <img
                        v-lazy="t.image"
                        :alt="t.post_title"
                        class="mr-3 media_image"
                      />
                      <div class="media-body">
                        <h3 class="media-body-text">
                          <router-link :to="'/magazine-details/' + t.id"
                            >{{ t.post_title }}
                          </router-link>
                        </h3>
                        <time class="post-date">{{
                          getYear(t.post_date)
                        }}</time>
                      </div>
                    </li>
                    <!--add section here-->
                    <div class="w-100" v-if="MagazineIndustryPulseAd2 != null">
                      <a :href="MagazineIndustryPulseAd2.adUrl" target="_blank">
                        <img
                          v-lazy="MagazineIndustryPulseAd2.image"
                          :alt="ads"
                          style="max-height: 150px; object-fit: cover"
                        />
                      </a>
                    </div>
                    <!--end dd section here-->
                  </ul>
                </div>
              </div>

              <!--end of IndustryPulse-->

              <!--technology-->
              <div class="td-block-title-wrap">
                <router-link :to="'/magazine/category/Technology'">
                  <h4 class="block-title td-block-title block-title-danger">
                    <span class="td-pulldown-size">Science & Technology</span>
                  </h4>
                </router-link>
              </div>
              <div class="row mb-5">
                <div
                  class="col-md-6"
                  v-for="(t, i) in (Technology || []).slice(0, 1)"
                  :key="i + 'a'"
                >
                  <div style="position: relative">
                    <img
                      v-lazy="t.image"
                      :alt="t.post_title"
                      class="img-fluid w-100"
                      style="max-height: 304px; object-fit: cover"
                    />
                    <router-link
                      :to="'magazine/category/' + c"
                      v-for="(c, j) in t.categories"
                      :key="j"
                      class="td-post-category"
                      >{{ c }}
                    </router-link>
                  </div>
                  <div class="td-module-meta-info">
                    <h3 class="entry-title">
                      <router-link :to="'/magazine-details/' + t.id">
                        {{ t.post_title }}
                      </router-link>
                    </h3>
                    <div class="td-editor-date">
                      <span class="author-date">
                        <span class="post-author"
                          ><a>{{ t.post_author }}</a>
                          <span v-if="t.post_date && t.post_author">-</span>
                        </span>
                        <span class="post-date"
                          ><time class="entry-date">{{
                            getYear(t.post_date)
                          }}</time></span
                        >
                        <!-- <span class="td-module-comments"
                          ><span href="">{{ t.comment_count }}</span></span
                        > -->
                      </span>
                    </div>
                    <div class="td-excerpt">
                      <div
                        v-html="
                          t.post_content.length > 120
                            ? t.post_content.substring(0, 120) + '...'
                            : t.post_content
                        "
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <ul class="list-unstyled">
                    <li
                      class="media mb-4"
                      v-for="(t, i) in (Technology || []).slice(1, 5)"
                      :key="i + 'b'"
                    >
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="mr-3 media_image"
                      />
                      <div class="media-body">
                        <h3 class="media-body-text">
                          <router-link :to="'/magazine-details/' + t.id"
                            >{{ t.post_title }}
                          </router-link>
                        </h3>
                        <time class="post-date">{{
                          getYear(t.post_date)
                        }}</time>
                      </div>
                    </li>
                    <!--add section here-->
                    <div class="w-100" v-if="MagazineTechnologyAd1 != null">
                      <a :href="MagazineTechnologyAd1.adUrl" target="_blank">
                        <img
                          v-lazy="MagazineTechnologyAd1.image"
                          :alt="MagazineTechnologyAd1.id"
                          style="max-height: 150px; object-fit: cover"
                        />
                      </a>
                    </div>
                    <!--end dd section here-->
                  </ul>
                </div>
              </div>

              <!--end of technology-->

              <div class="td-block-title-wrap">
                <router-link :to="'/magazine/category/Health'">
                  <h4 class="block-title td-block-title block-title-info">
                    <span class="td-pulldown-size">Health</span>
                  </h4>
                </router-link>
              </div>
              <div class="row mb-5">
                <div class="col-md-12">
                  <ul class="list-unstyled">
                    <li
                      class="media article mb-4"
                      v-for="(t, i) in (Health || []).slice(0, 4)"
                      :key="i + 'a'"
                    >
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="mr-3 media_image"
                      />
                      <div class="media-body">
                        <h3 class="entry-title">
                          <router-link :to="'/magazine-details/' + t.id"
                            >{{ t.post_title }}
                          </router-link>
                        </h3>
                        <div class="td-editor-date">
                          <span class="post-author"
                            ><a>{{ t.post_author }}</a>

                            <span v-if="t.post_date && t.post_author">-</span>
                          </span>
                          <span class="post-date"
                            ><time class="font-weight-bold">{{
                              getYear(t.post_date)
                            }}</time></span
                          >
                          <!-- <span class="td-module-comments"
                            ><span href="">{{ t.comment_count }}</span></span
                          > -->
                        </div>
                        <div class="td-excerpt">
                          <div
                            v-html="
                              t.post_content.length > 120
                                ? t.post_content.substring(0, 120) + '...'
                                : t.post_content
                            "
                          ></div>
                        </div>
                      </div>
                    </li>
                    <!--add section here-->
                    <div class="w-100" v-if="MagazineHealthAd1 != null">
                      <a :href="MagazineHealthAd1.adUrl" target="_blank">
                        <img
                          v-lazy="MagazineHealthAd1.image"
                          :alt="MagazineHealthAd1.id"
                          style="
                            width: 100%;
                            max-height: 350px;
                            object-fit: cover;
                          "
                        />
                      </a>
                    </div>
                    <!--end dd section here-->
                  </ul>
                </div>
              </div>
            </div>
            <!--end of articles-->

            <!--politics sidebar section-->
            <div class="col-md-4">
              <div class="td-block-title-wrap">
                <router-link :to="'/magazine/category/Politics'">
                  <h4 class="block-title td-block-title block-title-green">
                    <span class="td-pulldown-size bg-dark"
                      >WORLD NEWS & POLITICS</span
                    >
                  </h4>
                </router-link>
              </div>
              <div class="row mb-5">
                <div
                  class="col-6"
                  v-for="(t, i) in (Politics || []).slice(0, 4)"
                  :key="i + 'a'"
                >
                  <div style="position: relative" class="">
                    <img
                      v-lazy="t.image"
                      :alt="t.post_title"
                      class="img-fluid w-100"
                      style="max-height: 106px; object-fit: cover"
                    />
                    <router-link
                      :to="'/magazine/category/Politics'"
                      class="td-post-category"
                      >POLITICS
                    </router-link>
                  </div>
                  <div class="td-module-meta-info">
                    <h3 class="side-entry-title">
                      <router-link :to="'/magazine-details/' + t.id">
                        {{ t.post_title }}
                      </router-link>
                    </h3>
                  </div>
                </div>
                <!--add section here-->
                <div class="w-100" v-if="MagazinePoliticsAd1 != null">
                  <a :href="MagazinePoliticsAd1.adUrl" target="_blank">
                    <img
                      v-lazy="MagazinePoliticsAd1.image"
                      :alt="MagazinePoliticsAd1.id"
                      style="max-height: 150px; object-fit: cover"
                    />
                  </a>
                </div>
                <!--end dd section here-->
              </div>

              <!--end of politics-->

              <div class="row sticky-top">
                <!--travel-->
                <div class="col-12">
                  <div class="td-block-title-wrap">
                    <router-link :to="'/magazine/category/Travel'">
                      <h4 class="block-title td-block-title block-title-danger">
                        <span class="td-pulldown-size">Travel</span>
                      </h4>
                    </router-link>
                  </div>
                  <div
                    class="mb-4"
                    v-for="(t, i) in (Travel || []).slice(0, 1)"
                    :key="i + 'c'"
                  >
                    <div style="position: relative">
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="img-fluid w-100"
                        style="max-height: 304px; object-fit: cover"
                      />
                      <router-link
                        :to="'/magazine/category/Travel'"
                        class="td-post-category"
                        >Travel
                      </router-link>
                    </div>
                    <div class="td-module-meta-info mb-3">
                      <h3 class="entry-title">
                        <router-link :to="'/magazine-details/' + t.id">
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                      <div class="td-editor-date">
                        <span class="post-author"
                          ><a>{{ t.post_author }}</a>
                          <span v-if="t.post_date && t.post_author">-</span>
                        </span>
                        <span class="post-date"
                          ><time class="font-weight-bold">{{
                            getYear(t.post_date)
                          }}</time></span
                        >
                        <!-- <span class="td-module-comments"
                          ><span>{{ t.comment_count }}</span></span
                        > -->
                      </div>

                      <div class="td-excerpt">
                        <div
                          v-html="
                            t.post_content.length > 120
                              ? t.post_content.substring(0, 120) + '...'
                              : t.post_content
                          "
                        ></div>
                      </div>
                    </div>
                    <ul class="list-unstyled">
                      <li
                        class="media mb-4"
                        v-for="(t, i) in (Travel || []).slice(1, 3)"
                        :key="i"
                      >
                        <img
                          v-lazy="t.image"
                          :alt="t.post_title"
                          class="mr-3 media_image"
                        />
                        <div class="media-body">
                          <h3 class="media-body-text">
                            <router-link :to="'/magazine-details/' + t.id"
                              >{{ t.post_title }}
                            </router-link>
                          </h3>
                          <time class="post-date">{{
                            getYear(t.post_date)
                          }}</time>
                        </div>
                      </li>
                      <!--add section here-->
                      <div class="w-100" v-if="MagazineTravelAd1 != null">
                        <a :href="MagazineTravelAd1.adUrl" target="_blank">
                          <img
                            v-lazy="MagazineTravelAd1.image"
                            :alt="MagazineTravelAd1.id"
                            style="max-height: 150px; object-fit: cover"
                          />
                        </a>
                      </div>
                      <!--end dd section here-->
                    </ul>
                  </div>
                  <!--end of travel section-->

                  <!--food section-->
                  <div class="td-block-title-wrap">
                    <router-link :to="'/magazine/category/Food'">
                      <h4 class="block-title td-block-title block-title-grey">
                        <span class="td-pulldown-size bg-dark">Food & Drink</span>
                      </h4>
                    </router-link>
                  </div>
                  <div
                    class="mb-4"
                    v-for="(t, i) in (Food || []).slice(0, 1)"
                    :key="i + 'd'"
                  >
                    <div style="position: relative">
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="img-fluid w-100"
                        style="max-height: 304px; object-fit: cover"
                      />
                      <router-link
                        :to="'/magazine/category/Food'"
                        class="td-post-category"
                        >Food
                      </router-link>
                    </div>
                    <div class="td-module-meta-info mb-3">
                      <h3 class="entry-title">
                        <router-link :to="'/magazine-details/' + t.id">
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                      <div class="td-editor-date">
                        <span class="post-author"
                          ><a>{{ t.post_author }}</a>
                          <span v-if="t.post_date && t.post_author">-</span>
                        </span>
                        <span class="post-date"
                          ><time class="font-weight-bold">{{
                            getYear(t.post_date)
                          }}</time></span
                        >
                        <!-- <span class="td-module-comments"
                          ><span>{{ t.comment_count }}</span></span
                        > -->
                      </div>

                      <div class="td-excerpt">
                        <div
                          v-html="
                            t.post_content.length > 120
                              ? t.post_content.substring(0, 120) + '...'
                              : t.post_content
                          "
                        ></div>
                      </div>
                    </div>
                    <ul class="list-unstyled">
                      <li
                        class="media mb-4"
                        v-for="(t, i) in (Food || []).slice(1, 3)"
                        :key="i"
                      >
                        <img
                          v-lazy="t.image"
                          :alt="t.post_title"
                          class="mr-3 media_image"
                        />
                        <div class="media-body">
                          <h3 class="media-body-text">
                            <router-link :to="'/magazine-details/' + t.id"
                              >{{ t.post_title }}
                            </router-link>
                          </h3>
                          <time class="post-date">{{
                            getYear(t.post_date)
                          }}</time>
                        </div>
                      </li>
                      <!--add section here-->
                      <div class="w-100" v-if="MagazineFoodAd1 != null">
                        <a :href="MagazineFoodAd1.adUrl" target="_blank">
                          <img
                            v-lazy="MagazineFoodAd1.image"
                            :alt="MagazineFoodAd1.id"
                            style="max-height: 150px; object-fit: cover"
                          />
                        </a>
                      </div>
                      <!--end dd section here-->
                    </ul>
                  </div>
                  <!--end of food section-->
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="td-block-title-wrap">
                <router-link :to="'/magazine/category/Sports'">
                  <h4 class="block-title td-block-title block-title-danger">
                    <span class="td-pulldown-size bg-dark">Sports</span>
                  </h4>
                </router-link>
              </div>
              <div
                class="mb-4"
                v-for="(t, i) in (Sports || []).slice(0, 1)"
                :key="i + 'c'"
              >
                <div style="position: relative">
                  <img
                    v-lazy="t.image ? t.image : '../../assets/images/error.png'"
                    :alt="t.post_title"
                    class="img-fluid w-100"
                    style="max-height: 304px; object-fit: cover"
                  />
                  <router-link
                    :to="'/magazine/category/Travel'"
                    class="td-post-category"
                    >Sports
                  </router-link>
                </div>
                <div class="td-module-meta-info mb-3">
                  <h3 class="entry-title">
                    <router-link :to="'/magazine-details/' + t.id">
                      {{ t.post_title }}
                    </router-link>
                  </h3>
                  <div class="td-editor-date">
                    <span class="post-author"
                      ><a>{{ t.post_author }}</a>
                      <span v-if="t.post_date && t.post_author">-</span>
                    </span>
                    <span class="post-date"
                      ><time class="font-weight-bold">{{
                        getYear(t.post_date)
                      }}</time></span
                    >
                    <!-- <span class="td-module-comments"
                      ><span>{{ t.comment_count }}</span></span
                    > -->
                  </div>

                  <div class="td-excerpt">
                    <div
                      v-html="
                        t.post_content.length > 120
                          ? t.post_content.substring(0, 120) + '...'
                          : t.post_content
                      "
                    ></div>
                  </div>
                </div>
                <ul class="list-unstyled">
                  <li
                    class="media mb-4"
                    v-for="(t, i) in (Sports || []).slice(1, 3)"
                    :key="i"
                  >
                    <img
                      v-lazy="t.image"
                      :alt="t.post_title"
                      class="mr-3 media_image"
                    />
                    <div class="media-body">
                      <h3 class="media-body-text">
                        <router-link :to="'/magazine-details/' + t.id"
                          >{{ t.post_title }}
                        </router-link>
                      </h3>
                      <time class="post-date">{{ getYear(t.post_date) }}</time>
                    </div>
                  </li>
                  <!--add section here-->
                  <div class="w-100" v-if="MagazineSportsAd1 != null">
                    <a :href="MagazineSportsAd1.adUrl" target="_blank">
                      <img
                        v-lazy="MagazineSportsAd1.image"
                        :alt="MagazineSportsAd1.id"
                        style="max-height: 150px; object-fit: cover"
                      />
                    </a>
                  </div>
                  <!--end dd section here-->
                </ul>
              </div>
            </div>
            <div class="col-md-4">
              <div class="td-block-title-wrap">
                <router-link :to="'/magazine/category/LifeStyle'">
                  <h4 class="block-title td-block-title block-title-info">
                    <span class="td-pulldown-size bg-dark">Lifestyle</span>
                  </h4>
                </router-link>
              </div>
              <div
                class="mb-4"
                v-for="(t, i) in (LifeStyle || []).slice(0, 1)"
                :key="i + 'c'"
              >
                <div style="position: relative">
                  <img
                    v-lazy="t.image ? t.image : '../../assets/images/error.png'"
                    :alt="t.post_title"
                    class="img-fluid w-100"
                    style="max-height: 304px; object-fit: cover"
                  />
                  <router-link
                    :to="'/magazine/category/LifeStyle'"
                    class="td-post-category"
                    >Lifestyle
                  </router-link>
                </div>
                <div class="td-module-meta-info mb-3">
                  <h3 class="entry-title">
                    <router-link :to="'/magazine-details/' + t.id">
                      {{ t.post_title }}
                    </router-link>
                  </h3>
                  <div class="td-editor-date">
                    <span class="post-author"
                      ><a>{{ t.post_author }}</a>
                      <span v-if="t.post_date && t.post_author">-</span>
                    </span>
                    <span class="post-date"
                      ><time class="font-weight-bold">{{
                        getYear(t.post_date)
                      }}</time></span
                    >
                    <!-- <span class="td-module-comments"
                      ><span>{{ t.comment_count }}</span></span
                    > -->
                  </div>

                  <div class="td-excerpt">
                    <div
                      v-html="
                        t.post_content.length > 120
                          ? t.post_content.substring(0, 120) + '...'
                          : t.post_content
                      "
                    ></div>
                  </div>
                </div>
                <ul class="list-unstyled">
                  <li
                    class="media mb-4"
                    v-for="(t, i) in (LifeStyle || []).slice(1, 3)"
                    :key="i"
                  >
                    <img
                      v-lazy="t.image"
                      :alt="t.post_title"
                      class="mr-3 media_image"
                    />
                    <div class="media-body">
                      <h3 class="media-body-text">
                        <router-link :to="'/magazine-details/' + t.id"
                          >{{ t.post_title }}
                        </router-link>
                      </h3>
                      <time class="post-date">{{ getYear(t.post_date) }}</time>
                    </div>
                  </li>
                  <!--add section here-->
                  <div class="w-100" v-if="MagazineLifestyleAd1 != null">
                    <a :href="MagazineLifestyleAd1.adUrl" target="_blank">
                      <img
                        v-lazy="MagazineLifestyleAd1.image"
                        :alt="MagazineLifestyleAd1.id"
                        style="max-height: 150px; object-fit: cover"
                      />
                    </a>
                  </div>
                  <!--end dd section here-->
                </ul>
              </div>
            </div>
            <div class="col-md-4">
              <div class="td-block-title-wrap">
                <router-link :to="'/magazine/category/Tv'">
                  <h4 class="block-title td-block-title block-title-success">
                    <span class="td-pulldown-size bg-dark">Movies & Tv</span>
                  </h4>
                </router-link>
              </div>
              <div
                class="mb-4"
                v-for="(t, i) in (Tv || []).slice(0, 1)"
                :key="i + 'c'"
              >
                <div style="position: relative">
                  <img
                    v-lazy="t.image ? t.image : '../../assets/images/error.png'"
                    :alt="t.post_title"
                    class="img-fluid w-100"
                    style="max-height: 304px; object-fit: cover"
                  />
                  <router-link
                    :to="'/magazine/category/Tv'"
                    class="td-post-category"
                    >TV
                  </router-link>
                </div>
                <div class="td-module-meta-info mb-3">
                  <h3 class="entry-title">
                    <router-link :to="'/magazine-details/' + t.id">
                      {{ t.post_title }}
                    </router-link>
                  </h3>
                  <div class="td-editor-date">
                    <span class="post-author"
                      ><a>{{ t.post_author }}</a>
                      <span v-if="t.post_date && t.post_author">-</span>
                    </span>
                    <span class="post-date"
                      ><time class="font-weight-bold">{{
                        getYear(t.post_date)
                      }}</time></span
                    >
                    <!-- <span class="td-module-comments"
                      ><span>{{ t.comment_count }}</span></span
                    > -->
                  </div>

                  <div class="td-excerpt">
                    <div
                      v-html="
                        t.post_content.length > 120
                          ? t.post_content.substring(0, 120) + '...'
                          : t.post_content
                      "
                    ></div>
                  </div>
                </div>
                <ul class="list-unstyled">
                  <li
                    class="media mb-4"
                    v-for="(t, i) in (Tv || []).slice(1, 3)"
                    :key="i"
                  >
                    <img
                      v-lazy="t.image"
                      :alt="t.post_title"
                      class="mr-3 media_image"
                    />
                    <div class="media-body">
                      <h3 class="media-body-text">
                        <router-link :to="'/magazine-details/' + t.id"
                          >{{ t.post_title }}
                        </router-link>
                      </h3>
                      <time class="post-date">{{ getYear(t.post_date) }}</time>
                    </div>
                  </li>
                  <!--add section here-->
                  <div class="w-100" v-if="MagazineTvAd1 != null">
                    <a :href="MagazineTvAd1.adUrl" target="_blank">
                      <img
                        v-lazy="MagazineTvAd1.image"
                        :alt="MagazineTvAd1.id"
                        style="max-height: 150px; object-fit: cover"
                      />
                    </a>
                  </div>
                  <!--end dd section here-->
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="td-block-title-wrap">
                <router-link :to="'/magazine/category/LatestArticles'">
                  <h4 class="block-title td-block-title block-title-green">
                    <span class="td-pulldown-size">Latest Articles</span>
                  </h4>
                </router-link>
              </div>
              <div class="row mb-5">
                <div
                  class="col-md-6"
                  v-for="(t, i) in (LatestArticles || []).slice(0, 1)"
                  :key="i + 'c'"
                >
                  <div class="mb-4">
                    <div style="position: relative">
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="img-fluid w-100"
                        style="max-height: 304px; object-fit: cover"
                      />
                      <router-link
                        :to="'/magazine/category/LifeStyle'"
                        class="td-post-category"
                        >Latest Article
                      </router-link>
                    </div>
                    <div class="td-module-meta-info">
                      <h3 class="entry-title">
                        <router-link :to="'/magazine-details/' + t.id">
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                      <div class="td-editor-date">
                        <span class="post-author"
                          ><a>{{ t.post_author }}</a>
                          <span v-if="t.post_date && t.post_author">-</span>
                        </span>
                        <span class="post-date"
                          ><time class="font-weight-bold">{{
                            getYear(t.post_date)
                          }}</time></span
                        >
                        <!-- <span class="td-module-comments"
                          ><span href="">{{ t.comment_count }}</span></span
                        > -->
                      </div>

                      <div class="td-excerpt">
                        <div
                          v-html="
                            t.post_content.length > 120
                              ? t.post_content.substring(0, 120) + '...'
                              : t.post_content
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                  <ul class="list-unstyled">
                    <li
                      class="media mb-4"
                      v-for="(t, i) in LatestArticles.slice(3, 5)"
                      :key="i"
                    >
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="mr-3 media_image"
                      />
                      <div class="media-body">
                        <h3 class="media-body-text">
                          <router-link :to="'/magazine-details/' + t.id"
                            >{{ t.post_title }}
                          </router-link>
                        </h3>
                        <time class="post-date">{{
                          getYear(t.post_date)
                        }}</time>
                      </div>
                    </li>
                    <!--add section here-->
                    <div class="w-100" v-if="MagazineLatestArticleAd1 != null">
                      <a :href="MagazineLatestArticleAd1.adUrl" target="_blank">
                        <img
                          v-lazy="MagazineLatestArticleAd1.image"
                          :alt="MagazineLatestArticleAd1.id"
                          style="max-height: 150px; object-fit: cover"
                        />
                      </a>
                    </div>
                    <!--end dd section here-->
                  </ul>
                </div>
                <div
                  class="col-md-6"
                  v-for="(t, i) in (LatestArticles || []).slice(1, 2)"
                  :key="i + 'd'"
                >
                  <div class="mb-4">
                    <div style="position: relative">
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="img-fluid w-100"
                        style="max-height: 304px; object-fit: cover"
                      />
                      <router-link
                        :to="'/magazine/category/LifeStyle'"
                        class="td-post-category"
                        >Latest Article
                      </router-link>
                    </div>
                    <div class="td-module-meta-info">
                      <h3 class="entry-title">
                        <router-link :to="'/magazine-details/' + t.id">
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                      <div class="td-editor-date">
                        <span class="post-author"
                          ><a>{{ t.post_author }}</a>
                          <span v-if="t.post_date && t.post_author">-</span>
                        </span>
                        <span class="post-date"
                          ><time class="font-weight-bold">{{
                            getYear(t.post_date)
                          }}</time></span
                        >
                        <!-- <span class="td-module-comments"
                          ><span href="">{{ t.comment_count }}</span></span
                        > -->
                      </div>

                      <div class="td-excerpt">
                        <div
                          v-html="
                            t.post_content.length > 120
                              ? t.post_content.substring(0, 120) + '...'
                              : t.post_content
                          "
                        ></div>
                      </div>
                    </div>
                  </div>
                  <ul class="list-unstyled">
                    <li
                      class="media mb-4"
                      v-for="(t, i) in LatestArticles.slice(5, 7)"
                      :key="i"
                    >
                      <img
                        v-lazy="t.image"
                        :alt="t.post_title"
                        class="mr-3 media_image"
                      />
                      <div class="media-body">
                        <h3 class="media-body-text">
                          <router-link :to="'/magazine-details/' + t.id"
                            >{{ t.post_title }}
                          </router-link>
                        </h3>
                        <time class="post-date">{{
                          getYear(t.post_date)
                        }}</time>
                      </div>
                    </li>
                    <!--add section here-->
                    <div class="w-100" v-if="MagazineLatestArticleAd2 != null">
                      <a :href="MagazineLatestArticleAd2.adUrl" target="_blank">
                        <img
                          v-lazy="MagazineLatestArticleAd2.image"
                          :alt="MagazineLatestArticleAd2.id"
                          style="max-height: 150px; object-fit: cover"
                        />
                      </a>
                    </div>
                    <!--end dd section here-->
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row sticky-top">
                <div class="col-12">
                  <div class="td-block-title-wrap">
                    <router-link :to="'/magazine/category/AfricanCarribean'">
                      <h4 class="block-title td-block-title block-title-danger">
                        <span class="td-pulldown-size bg-dark"
                          >African Carribean</span
                        >
                      </h4>
                    </router-link>
                  </div>
                  <div
                    class="mb-4"
                    v-for="(t, i) in (AfricanCarribean || []).slice(0, 1)"
                    :key="i + 'c'"
                  >
                    <div style="position: relative">
                      <img
                        v-lazy="
                          t.image ? t.image : '../../assets/images/error.png'
                        "
                        :alt="t.post_title"
                        class="img-fluid w-100"
                        style="max-height: 304px; object-fit: cover"
                      />
                      <router-link
                        :to="'/magazine/category/Travel'"
                        class="td-post-category"
                        >African Carribean
                      </router-link>
                    </div>
                    <div class="td-module-meta-info mb-3">
                      <h3 class="entry-title">
                        <router-link :to="'/magazine-details/' + t.id">
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                      <div class="td-editor-date">
                        <span class="post-author"
                          ><a>{{ t.post_author }}</a>
                          <span v-if="t.post_date && t.post_author">-</span>
                        </span>
                        <span class="post-date"
                          ><time class="font-weight-bold">{{
                            getYear(t.post_date)
                          }}</time></span
                        >
                        <!-- <span class="td-module-comments"
                          ><span>{{ t.comment_count }}</span></span
                        > -->
                      </div>

                      <div class="td-excerpt">
                        <div
                          v-html="
                            t.post_content.length > 120
                              ? t.post_content.substring(0, 120) + '...'
                              : t.post_content
                          "
                        ></div>
                      </div>
                    </div>
                    <ul class="list-unstyled">
                      <li
                        class="media mb-4"
                        v-for="(t, i) in (AfricanCarribean || []).slice(1, 3)"
                        :key="i"
                      >
                        <img
                          v-lazy="t.image"
                          :alt="t.post_title"
                          class="mr-3 media_image"
                        />
                        <div class="media-body">
                          <h3 class="media-body-text">
                            <router-link :to="'/magazine-details/' + t.id"
                              >{{ t.post_title }}
                            </router-link>
                          </h3>
                          <time class="post-date">{{
                            getYear(t.post_date)
                          }}</time>
                        </div>
                      </li>
                      <!--add section here-->
                      <div
                        class="w-100"
                        v-if="MagazineAfricanCarribeanAd1 != null"
                      >
                        <a
                          :href="MagazineAfricanCarribeanAd1.adUrl"
                          target="_blank"
                        >
                          <img
                            v-lazy="MagazineAfricanCarribeanAd1.image"
                            :alt="MagazineAfricanCarribeanAd1.id"
                            style="max-height: 150px; object-fit: cover"
                          />
                        </a>
                      </div>
                      <!--end dd section here-->
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import Selectize from "vue2-selectize";

export default {
  name: "Home",
  data() {
    return {
      settings: this.search(),
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      movie_title: {},
      movie_player: "",
      poster_image: "",
      userInfo: this.$store.state.userInfo,
      AfricanCarribean: [],
      Articles: [],
      Business: [],
      Food: [],
      Header: [],
      IndustryPulse: [],
      LatestArticles: [],
      LifeStyle: [],
      Movies: [],
      Politics: [],
      PopularPost: [],
      Sports: [],
      Technology: [],
      Travel: [],
      Trending: [],
      Tv: [],
      Health: [],
      MagazineTopHeaderAd1: {},
      MagazineBusinessAd1: {},
      MagazineBusinessAd2: {},
      MagazinePoliticsAd1: {},
      MagazineIndustryPulseAd1: {},
      MagazineIndustryPulseAd2: {},
      MagazineTechnologyAd1: {},
      MagazineHealthAd1: {},
      MagazineTravelAd1: {},
      MagazineFoodAd1: {},
      MagazineSportsAd1: {},
      MagazineLifestyleAd1: {},
      MagazineTvAd1: {},
      MagazineLatestArticleAd1: {},
      MagazineLatestArticleAd2: {},
      MagazineAfricanCarribeanAd1: {},
      SeriesTopHeaderAd1: {},
      MoviesTopHeaderAd1: {},
      RadioTopHeaderAd1: {},
    };
  },
  methods: {
    search: function () {
      var me = this;
      return {
        valueField: "type",
        labelField: "post_title",
        searchField: "post_title",
        create: false,
        onItemAdd: function (value, $item) {
          let data1 = value.split("/")[0].replace(/\s/g, "");
          let data2 = value.split("/")[1].replace(/\s/g, "");
          console.log(data1, data2);
          if (data1 == "articlessearch") {
            me.$router.push("/magazine-details/" + data2);
          } else if (data1 == "allcelebrity") {
            me.$router.push("/celebrity-profile/" + data2);
          }
        },
        render: {
          option: function (item, escape) {
            let mydata = "";
            if (item.component === "articlessearch") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="selectize_img" src="' +
                escape(item.image) +
                '" alt="">' +
                escape(item.post_title) +
                "</span>" +
                "</span>" +
                '<span class="selectize_description">' +
                item.post_content +
                "</span>" +
                "</div>";
            } else if (item.component === "allcelebrity") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="celebrity_img" src="' +
                escape(item.photo) +
                '" alt="">' +
                escape(item.name) +
                "</span>" +
                "</span>" +
                (item.person_info
                  ? '<span class="selectize_description">' +
                    escape(item.person_info) +
                    "</span>"
                  : "") +
                "</div>";
            }
            return mydata;
          },
        },
        load: function (query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: "https://api.aukiss.tv/api/searcharticles",
            type: "POST",
            data: {
              post_title: encodeURIComponent(query),
            },
            error: function () {
              callback();
            },
            success: function (res) {
              let mydata = res.data;
              if (mydata.length > 0) {
                mydata.forEach((e) => {
                  e.type = res.message + "/" + e.id;
                  e.component = res.message.replace(/\s/g, "");
                  if (e.component == "allcelebrity") {
                    e.image = e.photo;
                    e.post_title = e.name;
                    e.post_content = e.person_info;
                  }
                });
                callback(mydata);
              }
            },
          });
        },
      };
    },

    getYear(d) {
      if (d) {
        return moment(new Date(d.replace(/-/g, "/"))).format("MMMM D, YYYY");
      }
    },
    getMagazineData() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/webmagazine")
        .then((json) => {
          this.Tv = json.data.Tv;
          this.Trending = json.data.Trending;
          this.Travel = json.data.Travel;
          this.Technology = json.data.Technology;
          this.Sports = json.data.Sports;
          this.PopularPost = json.data.PopularPost;
          this.Politics = json.data.Politics;
          this.Movies = json.data.Movies;
          this.LifeStyle = json.data.LifeStyle;
          this.LatestArticles = json.data.LatestArticles;
          this.IndustryPulse = json.data.IndustryPulse;
          this.Header = json.data.Header;
          this.Food = json.data.Food;
          this.Business = json.data.Business;
          this.Articles = json.data.Articles;
          this.Health = json.data.Health;
          this.AfricanCarribean = json.data.AfricanCarribean;
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
          this.$nextTick(() => {
            exeCarousel();
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getAdsData() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/adstartApp")
        .then((json) => {
          // console.log("adsa", json.data);
          // console.log("adsa", json.data.MagazineTopHeaderAd1);
          (this.MagazineTopHeaderAd1 = json.data.MagazineTopHeaderAd1),
            (this.MagazineBusinessAd1 = json.data.MagazineBusinessAd1),
            (this.MagazineBusinessAd2 = json.data.MagazineBusinessAd2),
            (this.MagazinePoliticsAd1 = json.data.MagazinePoliticsAd1),
            (this.MagazineIndustryPulseAd1 =
              json.data.MagazineIndustryPulseAd1),
            (this.MagazineIndustryPulseAd2 =
              json.data.MagazineIndustryPulseAd2),
            (this.MagazineTechnologyAd1 = json.data.MagazineTechnologyAd1),
            (this.MagazineHealthAd1 = json.data.MagazineHealthAd1),
            (this.MagazineTravelAd1 = json.data.MagazineTravelAd1),
            (this.MagazineFoodAd1 = json.data.MagazineFoodAd1),
            (this.MagazineSportsAd1 = json.data.MagazineSportsAd1),
            (this.MagazineLifestyleAd1 = json.data.MagazineLifestyleAd1),
            (this.MagazineTvAd1 = json.data.MagazineTvAd1),
            (this.MagazineLatestArticleAd1 =
              json.data.MagazineLatestArticleAd1),
            (this.MagazineLatestArticleAd2 =
              json.data.MagazineLatestArticleAd2),
            (this.MagazineAfricanCarribeanAd1 =
              json.data.MagazineAfricanCarribeanAd1),
            (this.SeriesTopHeaderAd1 = json.data.SeriesTopHeaderAd1),
            (this.MoviesTopHeaderAd1 = json.data.MoviesTopHeaderAd1),
            (this.RadioTopHeaderAd1 = json.data.RadioTopHeaderAd1);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading,
    Selectize,
  },
  created() {
    document.title = "Magazine || Aukiss TV";
    this.$loadScript("/assets/js/owl.carousel.min.js")
      .then(() => {
        this.$nextTick(() => {
          this.getMagazineData();
        });
        this.getAdsData();
      
        var elements = document.getElementsByClassName(
          "index-page sidebar-collapse"
        );
        for (var i = 0; i < elements.length; i++) {
          elements[i].removeAttribute("style");
        }
      })
      .catch(() => {});
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
  },
};
</script>

<style scoped>
@import url("../../public/assets/css/owl.carousel.css");
@import url("../../public/assets/css/owl.theme.css");
@import url("../../public/assets/css/owl.transitions.css");

.selectize-input {
  border-bottom: 1px solid #ccc !important;
}

.td-trending-now-title {
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #000000;
  padding: 2px 10px 1px;
  display: inline-block;
  line-height: 22px;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.td-trending-now-wrapper .td-next-prev-wrap {
  margin: 0 0 0 auto;
  z-index: 1;
}

.td-trending-now-display-area .entry-title {
  font-size: 15px;
  line-height: 25px;
  margin: 0;
}

.entry-title {
  word-wrap: break-word;
}

.td-next-prev-wrap a {
  display: inline-block;
  margin-right: 7px;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid #dcdcdc;
  text-align: center;
  vertical-align: middle;
  font-size: 7px;
  color: #b7b7b7;
}

.left_big_image {
  height: 454px;
  object-fit: cover;
}

.right_top_image {
  height: 254px;
  object-fit: cover;
  width: 100%;
}

.right_bottom_image {
  height: 192px;
  object-fit: cover;
  width: 100%;
}

.right_bottom:nth-child(2) {
  padding-right: 0px;
}

.right_bottom:nth-child(3) {
  padding-left: 0.5rem !important;
}

.meta-info {
  position: absolute;
  margin-bottom: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
  width: 90%;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 11px;
  margin-bottom: 7px;
  line-height: 1;
  min-height: 17px;
  cursor: pointer;
  padding: 22px 20px;
}

.tainer::after {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0)
  ) !important;
  border-radius: 0px;
}

.post-category {
  margin: 0 0 5px;
  padding: 3px 7px;
  background-color: rgba(0, 0, 0, 0.7);
  line-height: 23px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  background-color: #222222;
  color: #fff;
}

.post-title {
  font-size: 27px;
  font-weight: 500;
  line-height: 34px;
}

.post-title-sm {
  margin: 4px auto;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}

.post-author {
  font-weight: bold;
}

.post-date {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  font-size: 11px;
}

.all {
  /*height: 300px; !* Modify this according to your need *!*/
  overflow: hidden;
  /* Removing this will break the effects */
}

/* Quick-zoom Container */
.all img {
  transition: transform 0.5s, filter 1.5s ease-in-out;
}

.all .tainer::after {
  transition: transform 0.5s, filter 1.5s ease-in-out;
}

/* The Transformation */
.all:hover img {
  transform: scale(1.1);
}

.all:hover .tainer::after {
  transform: scale(1.1);
}

.block-title {
  border-color: #f9c100 !important;
}

.block-title {
  text-align: left;
}

.block-title {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 26px;
  border-bottom: 2px solid #222222;
}

.block-title > * {
  background-color: #f9c100 !important;
}

.td-block-title span {
  text-transform: uppercase !important;
}

.td-block-title span,
.td-block-title a {
  display: inline-block;
}

.block-title a,
.block-title span,
.block-title label {
  line-height: 17px;
  display: inline-block;
  padding: 7px 12px 4px;
  background-color: #222222;
  color: #fff;
}

.td-module-comments {
  display: block;
}

.td-module-comments {
  position: relative;
  float: right;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
}

.td-module-comments span {
  color: #fff;
  background-color: #000;
  display: inline-block;
  min-width: 17px;
  padding: 3px 4px 4px 5px;
  position: relative;
}

.td-module-comments span:after {
  position: absolute;
  bottom: -3px;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 0;
  border-color: #000 transparent transparent transparent;
}

.block-title-dark {
  border-color: #222222 !important;
}

.block-title-dark > * {
  background-color: #222222 !important;
}

.block-title-green {
  border-color: #0599a9 !important;
}

.block-title-green > * {
  background-color: #0599a9 !important;
}

.block-title-danger {
  border-color: #f44336 !important;
}

.block-title-danger > * {
  background-color: #f44336 !important;
}

.block-title-grey {
  border-color: #607d8b !important;
}

.block-title-grey > * {
  background-color: #607d8b !important;
}

.block-title-info {
  border-color: #4db2ec !important;
}

.block-title-info > * {
  background-color: #4db2ec !important;
}

.entry-title {
  font-size: 21px;
  line-height: 25px;
  margin: 0 0 6px 0;
}

.td-post-category {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  top: auto;
}

.td-post-category {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  background-color: #222222;
  color: #fff;
  margin-right: 5px;
  padding: 3px 6px 4px;
  display: block;
  vertical-align: top;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.td-module-meta-info {
  padding: 13px 0 0 0;
}

.td-module-meta-info {
  width: 100%;
  margin-bottom: 0;
  z-index: 1;
  /* border: 0 solid #eaeaea; */
  min-height: 0;
}

.td-module-meta-info {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 11px;
  margin-bottom: 7px;
  line-height: 1;
  min-height: 17px;
}

.td-excerpt {
  font-size: 13px;
  color: #777;
  font-family: "Open Sans", arial, sans-serif;
  line-height: 21px;
  margin: 20px 0 0;
}

.media_image {
  width: 92px;
  height: 63px;
  object-fit: cover;
}

.article img {
  width: 223px;
  height: 156px;
  object-fit: cover;
}

.media-body-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
}

.media:hover a {
  color: #f9c100;
}

.td-module-meta-info:hover a {
  color: #f9c100;
}

.side-entry-title {
  font-size: 13px !important;
  line-height: 1.3 !important;
  font-weight: 500 !important;
  margin: 0 0 35px 0;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .article {
    display: block !important;
  }

  .article img {
    width: 100%;
  }

  .side-right {
    display: -webkit-box;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .side-right img {
    height: 254px !important;
  }

  .side-right .all {
    padding-right: 0px !important;
    padding-left: 0px !important;
    max-width: 288px !important;
    /*height: 180px !important;*/
  }

  .side-right .meta-info .post-title {
    pointer-events: none !important;
    margin: 4px auto;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }

  .left_big_image {
    height: 320px !important;
  }

  .left_big {
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0.5rem;
  }

  .left_big_info .post-title {
    pointer-events: none !important;
    margin: 4px auto;
    font-size: 16px !important;
    line-height: 21px;
    font-weight: 500;
  }

  .all .tainer::after {
    background: linear-gradient(
      0deg,
      rgb(0 0 0),
      rgba(0, 0, 0, 0) 90%,
      rgb(0 0 0 / 0%)
    ) !important;
  }

  .right_bottom:nth-child(2) {
    padding-left: 0.5rem !important;
    padding-right: 0px;
  }

  .right_bottom:nth-child(3) {
    padding-left: 0.5rem !important;
    padding-right: 0px;
  }
}
</style>
